export default async function (path = '', messageType = '') {
  try {
    const app = useNuxtApp()
    if (path.indexOf('/ua/') === 0) {
      path = path.replace('/ua/', '/')
      const [basePath, queryString] = path.split('?')

      // const decodedQuery = decodeURIComponent(decodeURIComponent(queryString))

      let decodedQuery = ''
      if (queryString) {
        decodedQuery = decodeURIComponent(decodeURIComponent(queryString))
      }

      return app.runWithContext(() =>
        navigateTo(`${basePath}${decodedQuery ? '?' + decodedQuery : ''}`, {
          redirectCode: 301,
          replace: true,
          external: true
        })
      )
    }

    const res = await useApi().redirect.getOne(path)
    console.log('useRedirect', {
      path,
      destination: res?.destination,
      messageType
    })

    return app.runWithContext(() =>
      navigateTo(res.destination, {
        redirectCode: 301,
        replace: true,
        external: true
      })
    )
  } catch (e) {
    console.log('useRedirect error', {
      path,
      messageType,
      e
    })
    throw createError({
      statusCode: 404,
      statusMessage: `${messageType} Not Found`
    })
  }
}
